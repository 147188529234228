<template>
  <div class="protect_person_information">
    <item-title :title="{name: '个人信息保护', en_1: 'MINGYA', en_2: 'INFORMATION'}" />
    <div class="protect-content">
      <div class="list">
        <div @click="toDetail(item)" v-for="(item, index) in listData" :key="index" class="list-item">
          <span class="name">{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
export default {
  name:'ProtectPersonInformation',
  components: {
    ItemTitle
  },
  data() {
    return {
      listData: [{
        id: 1,
        name: '个人信息收集清单'
      },{
        id: 2,
        name: '第三方信息共享清单'
      }],
    }
  },
  mounted() {
    this.$emit('loading', false)
  },
  methods: {
    // 跳转详情
    toDetail(item) {
      this.$emit('loading', true)
      const name = item.id === 1 ? 'PersonInformation' : 'ThirdInformation'
      this.$router.push({ name })
    },
  }
}
</script>

<style lang="scss" scoped>
.protect_person_information {
  .list {
    margin-top: 0.6rem;
    padding: 0 0.05rem;
    .list-item {
      width: 100%;
      padding: .16rem .24rem .16rem .24rem;
      box-shadow: 0 0 .08rem 0 rgba(0, 0, 0, .17);
      border-radius: .12rem;
      margin-bottom: .2rem;
      box-sizing: border-box;
      cursor: pointer;
      .name{
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .protect_person_information {
    .list {
      .list-item {
        width: 100%;
      }
    }
  }
}
</style>